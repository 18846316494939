import {useContext, useMemo} from "react"
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "../../../../app/contexts/user/UserContext";
import {
  addShipmentBilling,
  billShipment,
  checkDuplicateAwb,
  confirmShipmentRequestLite,
  createShipmentRequestLite,
  getBilledShipmentRequestLite,
  getBilledShipmentRequestLiteDetail,
  getConfirmedShipmentRequestLite,
  getConfirmedShipmentRequestLiteDetail,
  getFinalShipmentDetail,
  getFinalShipments,
  getShipmentRequestLite,
  getShipmentRequestLiteDetail,
  importProductsFromFile,
  updateConfirmedShipmentRequestLite,
  updateShipmentRequestLite,
  updateShipmentStatus,
  updateWeight,
  verifyWeight,
} from "../../../api/nepal_lite/nepallite-api";

export const useCreateShipmentRequestLite = ({ onSuccess }) => {
  return useMutation(
    ["createShipmentRequestLite"],
    (formData) => createShipmentRequestLite(formData),
    {
      onSuccess: (data, variables, context) => {
        localStorage.removeItem("addShipmentRequest-nepal");
        toast.success("Succesfully requested for shipping");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useCheckDuplicateAwb = ({ onSuccess }) => {
  return useMutation(["checkDuplicateAwb"], (awb) => checkDuplicateAwb(awb), {
    onSuccess: (data, variables, context) => {
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};

export const useGetShipmentRequestLite = ({ filter }) => {
  return useQuery(
    ["getShipmentRequestLite", filter],
    () => getShipmentRequestLite(filter),
    {
      cacheTime: 120000,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetShipmentRequestLiteDetail = (id) => {
  return useQuery(
    ["getShipmentRequestLiteDetail", id],
    () => getShipmentRequestLiteDetail(id),
    {
      cacheTime: 120000,
      refetchInterval: false,
      refetchOnWindowFocus: true,
    }
  );
};

export const useUpdateShipmentRequestLite = ({ onSuccess }) => {
  const { role } = useContext(UserContext);
  const navigate = useNavigate({});
  return useMutation(
    ["updateShipmentRequestLite"],
    (formData) => updateShipmentRequestLite(formData),
    {
      onSuccess: (data, variables, context) => {
        localStorage.removeItem("addShipmentRequest-nepal");
        navigate(`/${role === "SysAdmin" ? "super" : "admin"}/shipment-lite`);
        toast.success("Succesfully updated shipping");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useGetConfirmedShipmentRequestLite = ({ filter }) => {
  return useQuery(
    ["getConfirmedShipmentRequestLite", filter],
    () => getConfirmedShipmentRequestLite(filter),
    {
      cacheTime: 120000,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetConfirmedShipmentRequestLiteDetail = (id) => {
  return useQuery(
    ["getConfirmedShipmentRequestLiteDetail", id],
    () => getConfirmedShipmentRequestLiteDetail(id),
    {
      cacheTime: 120000,
      refetchInterval: false,
      refetchOnWindowFocus: true,
    }
  );
};

export const useUpdateConfirmedShipmentRequestLite = ({ onSuccess }) => {
  const { role } = useContext(UserContext);
  const navigate = useNavigate({});
  return useMutation(
    ["updateConfirmedShipmentRequestLite"],
    (formData) => updateConfirmedShipmentRequestLite(formData),
    {
      onSuccess: (data, variables, context) => {
        localStorage.removeItem("addShipmentRequest-nepal");
        navigate(
          `/${role === "SysAdmin" ? "super" : "admin"}/shipment-${data?.id}`
        );
        toast.success("Succesfully updated shipping");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useConfirmShipmentRequestLite = ({ onSuccess }) => {
  const { role } = useContext(UserContext);
  const navigate = useNavigate({});
  return useMutation(
    ["updateShipmentRequestLite"],
    (id) => confirmShipmentRequestLite(id),
    {
      onSuccess: (data, variables, context) => {
        navigate(`/${role === "SysAdmin" ? "super" : "admin"}/final-shipments`);
        toast.success("Succesfully confirmed shipping request");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useAddShipmentBilling = ({ onSuccess }) => {
  const { role } = useContext(UserContext);
  const navigate = useNavigate();
  return useMutation(
    ["addShipmentBilling"],
    (formData) => addShipmentBilling(formData),
    {
      onSuccess: (data, variables, context) => {
        navigate(
          `/${role === "SysAdmin" ? "super" : "admin"}/shipment-${data?.id}`
        );
        toast.success("Succesfully added shipment bill");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useImportProductsFromFile = ({ onSuccess }) => {
  return useMutation(
    ["importProductsFromFile"],
    (formData) => importProductsFromFile(formData),
    {
      onSuccess: (data, variables, context) => {
        toast.success("Succesfully uploaded");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useGetBilledShipmentRequestLite = ({ filter }) => {
  return useQuery(
    ["getBilledShipmentRequestLite", filter],
    () => getBilledShipmentRequestLite(filter),
    {
      cacheTime: 120000,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetBilledShipmentRequestLiteDetail = (id) => {
  return useQuery(
    ["getBilledShipmentRequestLiteDetail", id],
    () => getBilledShipmentRequestLiteDetail(id),
    {
      cacheTime: 120000,
      refetchInterval: false,
      refetchOnWindowFocus: true,
    }
  );
};

export const useBillShipment = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(["billShipment"], (formData) => billShipment(formData), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["getFinalShipmentDetail", data?.id]);
      toast.success("Succesfully added shipment bill");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};

export const useGetFinalShipment = ({ filter, searchValue }) => {
    const hasValidDates = useMemo(() => {
        // Ensure valid date format (year 20xx) before enabling API call
        return (
            filter?.requestDateFrom !== "Invalid date" &&
            filter?.requestDateTo !== "Invalid date" &&
            /^20\d{2}/.test(filter.requestDateFrom) &&  // Check for '20xx' format
            /^20\d{2}/.test(filter.requestDateTo)
        );
    }, [filter]);

    return useQuery(
        ["getFinalShipments", filter, searchValue],
        () => getFinalShipments({ filter, searchValue }),
        {
            cacheTime: 120000,
            refetchInterval: false,
            enabled: hasValidDates,
        }
    );
};

export const useGetFinalShipmentDetail = (id) => {
  return useQuery(
    ["getFinalShipmentDetail", id],
    () => getFinalShipmentDetail(id),
    {
      cacheTime: 120000,
      refetchInterval: false,
      refetchOnWindowFocus: true,
    }
  );
};

export const useVerifyWeight = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(["verifyWeight"], (formData) => verifyWeight(formData), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["getFinalShipments"]);
      queryClient.invalidateQueries(["getFinalShipmentDetail"]);
      toast.success("Succesfully verified weight");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};

export const useUpdateWeight = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(["updateWeight"], (values) => updateWeight(values), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["getFinalShipments"]);
      toast.success("Succesfully verified weight");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};

export const useUpdateShipmentStatus = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["updateShipmentStatus"],

    (formData) => updateShipmentStatus(formData),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["getFinalShipments"]);
        queryClient.invalidateQueries(["getFinalShipmentDetail"]);
        toast.success("Succesfully updated shipment status");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};
